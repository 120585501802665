import React from 'react';
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import urljoin from 'url-join';
import { DiscussionEmbed } from 'disqus-react';
import Layout3 from '../components/layout3';
import SEO from '../components/seo';
import PostCard from '../components/post-card/post-card';
import PostDetails from '../components/post-details/post-details';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  RedditShareButton,
} from 'react-share';
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoPinterest,
  IoLogoReddit,
} from 'react-icons/io';
import {
  BlogPostDetailsWrapper,
  RelatedPostWrapper,
  RelatedPostItems,
  RelatedPostTitle,
  RelatedPostItem,
  BlogPostFooter,
  PostShare,
  PostTags,
  BlogPostComment,
} from './templates.style';

import Sticky from 'react-stickynode';
import Cityset1 from "../components/citysetting/citytitle1"
import Styles from "./blog-post.module.css"
import Cesiumtab from "../containers/cesiumtab"




const BlogPostTemplate = (props: any) => {
  const post = props.data.markdownRemark;
  const { edges } = props.data.allMarkdownRemark;
  const title = post.frontmatter.title;
  const slug = post.fields.slug;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const shareUrl = urljoin(siteUrl, slug);

  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: slug, title },
  };
  return (
    <Layout3>
      
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />


        <Sticky top={0} innerZ={9999} activeClass="nav-sticky">
          <div>
          <div className={Styles.bar}>
            <div style={{display:"flex"}}>
              <div className={Styles.headerleft}>
                <Cityset1 />
              </div>

              <div className={Styles.headerno}>
              <img style={{width:"70px",margin:"2px"}} src="/sdgsicon/sdg_07.png"/>
              </div>


              <div className={Styles.headerno}>
                {post.frontmatter.no}
              </div>
              <div className={Styles.headertitle}>
                {post.frontmatter.title}
              </div>
              <div className={Styles.headerright}>
                <a className={Styles.button} href="/top">HOME</a>
              </div>
            </div>
          </div>
          </div>
        </Sticky>



      <BlogPostDetailsWrapper>
        <PostDetails
          title={post.frontmatter.title}
          date={post.frontmatter.date}
          preview={
            post.frontmatter.cover == null
              ? null
              : post.frontmatter.cover.childImageSharp.fluid
          }
          description={post.html}
          imagePosition="left"
        />

<div className={Styles.tablediv}>
<div className={Styles.table}>
  <div>
    <h2>高さ</h2>
    <table>
      <thead>
        <tr>
          <th className={Styles.row1}></th>
          <th className={Styles.row2}>解説</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>名称</td>
          <td>{post.frontmatter.height_name}</td>
          </tr>
          <tr>
          <td>年次</td>
          <td>{post.frontmatter.year_display}</td>
          </tr>
          <tr>
          <td>出典</td>
          <td>{post.frontmatter.height_origin}</td>
          </tr>
          <tr>
          <td>定義</td>
          <td>{post.frontmatter.height_origin_note}</td>
          </tr>
          <tr>
          <td>詳細</td>
          <td><a href={post.frontmatter.height_origin_url}>{post.frontmatter.height_origin}</a></td>
        </tr>
      </tbody>
    </table>
</div>
</div>


<div className={Styles.tablediv}>
<div className={Styles.table}>
  <div>
    <h2>色</h2>
  <table>
  <thead>
  <tr>
  <th className={Styles.row1}></th>
  <th className={Styles.row2}>解説</th>
  </tr>
  </thead>
  <tbody>
  <tr>
  <td>名称</td>
  <td>{post.frontmatter.color_name}</td>
  </tr>
  <tr>
  <td>年次</td>
  <td>{post.frontmatter.year_display}</td>
  </tr>
  <tr>
  <td>出典</td>
  <td>{post.frontmatter.color_origin}</td>
  </tr>
  <tr>
  <td>定義</td>
  <td>{post.frontmatter.color_origin_note}</td>
  </tr>
  <tr>
  <td>詳細</td>
  <td><a href="{post.frontmatter.color_origin_url}">{post.frontmatter.color_origin}</a></td>
  </tr>
  </tbody>
  </table>
</div>
</div>

</div>
</div>

  
<div>
<Cesiumtab />
</div>

        <BlogPostFooter
          className={post.frontmatter.cover == null ? 'center' : ''}
        >
          {post.frontmatter.tags == null ? null : (
            <PostTags className="post_tags">
              {post.frontmatter.tags.map((tag: string, index: number) => (
                <Link key={index} to={`/tags/${_.kebabCase(tag)}/`}>
                  {`#${tag}`}
                </Link>
              ))}
            </PostTags>
          )}
          <PostShare>
            <span>Share This:</span>
            <FacebookShareButton url={shareUrl} quote={post.excerpt}>
              <IoLogoFacebook />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} title={title}>
              <IoLogoTwitter />
            </TwitterShareButton>
{/*
            <PinterestShareButton
              url={shareUrl}
              media={urljoin(siteUrl, post.frontmatter.cover.publicURL)}
            >
              <IoLogoPinterest />
            </PinterestShareButton>
*/}
            <RedditShareButton
              url={shareUrl}
              title={`${post.frontmatter.title}`}
            >
              <IoLogoReddit />
            </RedditShareButton>
          </PostShare>
        </BlogPostFooter>
        <BlogPostComment
          className={post.frontmatter.cover == null ? 'center' : ''}
        >
          <DiscussionEmbed {...disqusConfig} />
        </BlogPostComment>
      </BlogPostDetailsWrapper>



      {edges.length !== 0 && (
        <RelatedPostWrapper>
          <RelatedPostTitle>Related Posts</RelatedPostTitle>
          <RelatedPostItems>
            {edges.map(({ node }: any) => (
              <RelatedPostItem key={node.fields.slug}>
                <PostCard
                  title={node.frontmatter.title || node.fields.slug}
                  url={node.fields.slug}
                  image={
                    node.frontmatter.cover == null
                      ? null
                      : node.frontmatter.cover.childImageSharp.fluid
                  }
                  tags={node.frontmatter.tags}
                />
              </RelatedPostItem>
            ))}
          </RelatedPostItems>
        </RelatedPostWrapper>
      )}
    </Layout3>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        no
        title
        date(formatString: "DD MMM, YYYY")
        description
        tags
        cover {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1170, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        sdgs_no
        sdgs_main
        sdgs_subcat
        kou_tisou_pt
        kou_tisou_chp
        kou_tisou_sec
        kou_tisou_sbsec
        kou_tisou_title
        kou_titan_pt
        kou_titan_chp
        kou_titan_sec
        kou_titan_sbsec
        kou_titan_title
        height_name
        height_origin
        height_origin_note
        height_origin_url
        height_origin_url2
        color_name
        color_origin
        color_origin_note
        color_origin_url
        color_origin_url2
        sokuchi
        mesh_size
        year_display
        honbun
        chyusyaku
        hosoku
        tags
        kml_file
        y202012
        y202101
        y202102
        description
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: $tag } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            no
            title
            tags
            cover {
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, maxHeight: 285, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }

            sdgs_no
            sdgs_main
            sdgs_subcat
            kou_tisou_pt
            kou_tisou_chp
            kou_tisou_sec
            kou_tisou_sbsec
            kou_tisou_title
            kou_titan_pt
            kou_titan_chp
            kou_titan_sec
            kou_titan_sbsec
            kou_titan_title
            height_name
            height_origin
            height_origin_note
            height_origin_url
            height_origin_url2
            color_name
            color_origin
            color_origin_note
            color_origin_url
            color_origin_url2
            sokuchi
            mesh_size
            year_display
            honbun
            chyusyaku
            hosoku
            tags
            kml_file
            y202012
            y202101
            y202102
            description
          }
        }
      }
    }
  }
`;

